import React, { useState, useEffect, useRef } from 'react';
import { ApiCaller } from '../utils/APIConnect';
import { fetchApi } from '../utils/APICall';
import { Box, Heading, Text, VStack, HStack, Button, Link, Icon, Tabs, TabList, Tab, TabPanels, TabPanel, Skeleton } from '@chakra-ui/react';
import { useLocation, NavLink } from 'react-router-dom';
import { BsLinkedin, BsAt, BsPersonCircle } from "react-icons/bs";
import { convertToTitleCase } from '../utils/textFunctions';
import StubsHeader from './Header';
import StubsFooter from './Footer';

const ShowResults = () => {
  const location = useLocation();
  const { searchTerm } = location.state || { searchTerm: '' }; // Get the search term from state

  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 25; // Limit results to 25
  const [activeTab, setActiveTab] = useState(0); // Default to "By Name"
  const [fullResults, setFullResults] = useState([]); // To store detailed results
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMoreResults, setHasMoreResults] = useState(true); // Track if more results are available
  const resultsRef = useRef(null); // Ref to the results container

  const fetchFullResults = async () => {
    let detailedResults = [];
    try {
        setLoading(true); // Start loading
        if (activeTab === 0) {
            // Fetch detailed results for people
            detailedResults = await ApiCaller('searchPeople_expanded', { name: searchTerm, numResults: resultsPerPage, startAt: (currentPage - 1) * resultsPerPage });
            console.log("Looking up by name");
        } else if (activeTab === 1) {
            // Fetch detailed results for job titles
            detailedResults = await ApiCaller('searchPeople_byTitle', { jobtitle: searchTerm });
            console.log("Looking up by title");
        } else if (activeTab === 2) {
            // Fetch detailed results for organizations
            detailedResults = await ApiCaller('searchPeople_byOrg', { lookup: searchTerm });
            console.log("Looking up by org");
        }

        if (detailedResults.length > 0) {
            setFullResults(detailedResults);
        } else {
            setHasMoreResults(false); // No more results available
        }
    } catch (error) {
        console.error("Error fetching full results:", error);
        setFullResults([]); // Set to empty array on error
    } finally {
        setLoading(false); // End loading
    }
  };

  useEffect(() => {
      fetchFullResults();
  }, [activeTab, currentPage]); // Depend on activeTab and currentPage

  // Handle page change
  const handlePageChange = () => {
    if (hasMoreResults) {
      setCurrentPage(prevPage => prevPage + 1); // Increment page number
      // Scroll to the results container if ref is available
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh" padding={4}>
      <StubsHeader />

      {/* Left Column (20% White Space) */}
      <Box flex="0.1" bg="white" />

      {/* Right Column Container */}
      <Box flex="0.9" display="flex" flexDirection="column" padding={4} mt={16}>
        {/* Search Results Heading */}
        <Heading size="lg" width="100%" mb={4}>
          Search Results for: {searchTerm}
        </Heading>

        {/* Tabs for Search Types */}
        <Tabs onChange={(index) => setActiveTab(index)} defaultIndex={0}>
          <TabList>
            <Tab>By Name</Tab>
            <Tab>By Job Title</Tab>
            <Tab>By Organization</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* Displaying results for By Name */}
              {loading ? (
                <Skeleton height="200px" width="100%" mb={4} />
              ) : (
                <ResultsList results={fullResults} resultsRef={resultsRef} />
              )}
            </TabPanel>
            <TabPanel>
              {/* Displaying results for By Job Title */}
              {loading ? (
                <Skeleton height="200px" width="100%" mb={4} />
              ) : (
                <ResultsList results={fullResults} resultsRef={resultsRef} />
              )}
            </TabPanel>
            <TabPanel>
              {/* Displaying results for By Organization */}
              {loading ? (
                <Skeleton height="200px" width="100%" mb={4} />
              ) : (
                <ResultsList results={fullResults} resultsRef={resultsRef} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* Next Page Button */}
        {hasMoreResults && (
          <Button onClick={handlePageChange} colorScheme="teal" mt={4}>
            Next Page
          </Button>
        )}
        {!hasMoreResults && <Text mt={4}>No more search results.</Text>}
      </Box>

      <StubsFooter />
    </Box>
  );
};

// Component to render the list of results
const ResultsList = React.forwardRef(({ results }, ref) => {
  const safeResults = results || []; // Default to an empty array if results is null or undefined

  return (
    <Box ref={ref} flex="1" padding={4} bg="#F7FAFC">
      <VStack spacing={6} align="start">
        <Box borderWidth="1px" borderRadius="md" padding={4} bg="white" width="100%">
          {safeResults.length > 0 ? (
            <>
              <Heading size="md" mb={2}>Search Results</Heading>
              <Text fontSize="md" color="gray.600" mt={2} mb={4}>
                <strong>Found {safeResults.length} search results.</strong>
              </Text>
            </>
          ) : (
            <Text>No results found.</Text> // Message when there are no results
          )}

          {/* Current Results */}
          {safeResults.map((result, index) => (
            <Box key={index} borderWidth="1px" borderRadius="md" padding={4} mb={4} bg="white" width="100%">
              <VStack spacing={4} align="start">
                <NavLink to={`/person/${result.id}`} style={{ textDecoration: 'none' }}>
                  <HStack spacing={5}>
                    <Icon as={BsPersonCircle} boxSize={12} color="teal.500" />
                    <HStack>
                      <VStack align="start" spacing={0}>
                        <Heading size="md">{convertToTitleCase(result.person_name)}</Heading>
                        <Text fontSize="lg">{convertToTitleCase(result.title)}</Text>
                      </VStack>
                    </HStack>
                  </HStack>
                </NavLink>
                <Text fontWeight="bold" mb={1}>{convertToTitleCase(result.name)}</Text>
                <Text>{result.street}, {result.zip}, {result.state}</Text>
                <HStack spacing={4}>
                  <Link href={`mailto:${result.email}`}>
                    <Button leftIcon={<BsAt />} variant="ghost" colorScheme="teal">
                      Email
                    </Button>
                  </Link>
                  <Link href={result.linkedin} isExternal>
                    <Button leftIcon={<BsLinkedin />} variant="ghost" colorScheme="teal">
                      LinkedIn
                    </Button>
                  </Link>
                </HStack>
              </VStack>
            </Box>
          ))}
        </Box>
      </VStack>
    </Box>
  );
});

export default ShowResults;
