import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import StubsHeader from './Header';
import StubsFooter from './Footer';

const FAQ = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh" padding={4}>
      <StubsHeader />

      {/* Left Column (20% White Space) */}
      <Box flex="0.1" bg="white" />

      {/* Right Column Container */}
      <Box flex="0.9" display="flex" width="70%" flexDirection="column" padding="75px" bg="#F7FAFC">
        <Heading size="lg" mb={4}>FAQ</Heading>
        <Text fontSize="lg" mb={4}>
          Here are some frequently asked questions about Stubs:
        </Text>
        <UnorderedList spacing={3} fontSize="lg">
          <ListItem>
            <strong>What is Stubs?</strong> Stubs is a salary transparency database for nonprofit organizations. We aggregate publicly available data from IRS Form 990 filings to provide insights into salary trends, helping to drive equity in compensation within the nonprofit sector.
          </ListItem>
          <ListItem>
            <strong>How do you source your data?</strong> All salary information in Stubs is sourced from Form 990 filings, which are public documents filed by nonprofit organizations in the United States. We extract relevant salary data from these filings, making it easy for you to explore and compare compensation across organizations.
          </ListItem>
          <ListItem>
            <strong>Can I search for specific roles or organizations?</strong> Yes! You can search by person name, job title, or organization name, and our intelligent query system will return relevant results quickly and accurately.
          </ListItem>
          <ListItem>
            <strong>How up-to-date is the salary information?</strong> The data in Stubs is based on the latest available Form 990 filings, which are typically filed annually. We update our database regularly to ensure the information remains current.
          </ListItem>
          <ListItem>
            <strong>Is there a fee to use Stubs?</strong> Stubs is free to use for individual searches. We also offer premium compensation analysis reports for organizations and boards that need more in-depth insights. We have not launched these features yet. If you are interested in custom reporting please contact stubsapp@gmail.com.
          </ListItem>
          <ListItem>
            <strong>How do you ensure data accuracy?</strong> We use automated tools to extract data from the Form 990s, and while we strive for accuracy, the data is dependent on the filings themselves. If you find any discrepancies, please let us know, and we will review the data.
          </ListItem>
          <ListItem>
            <strong>Can I download reports from Stubs?</strong> Yes, we offer customized compensation analysis reports that provide detailed insights into salary trends based on location, organization size, and more. These reports are available for purchase, however we have not launched an online purchase features yet. If you are interested in custom reporting please contact stubsapp@gmail.com.
          </ListItem>
        </UnorderedList>
      </Box>

      <StubsFooter />
    </Box>
  );
};

export default FAQ;