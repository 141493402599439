import React, { useState, useEffect, useRef } from 'react';
import { ApiCaller } from '../utils/APIConnect';
import { fetchApi } from '../utils/APICall';
import { Box, Heading, Text, VStack, HStack, Button, Link, Icon, Tabs, TabList, Tab, TabPanels, TabPanel, Spinner } from '@chakra-ui/react';
import { useLocation, NavLink } from 'react-router-dom';
import { BsLinkedin, BsAt, BsPersonCircle } from "react-icons/bs";
import { convertToTitleCase } from '../utils/textFunctions';
import StubsHeader from './Header';
import StubsFooter from './Footer';

const ShowResults = () => {
  const location = useLocation();
  const { searchTerm } = "John Smith";

  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 25; // Limit results to 25
  const [activeTab, setActiveTab] = useState(0); // Default to "By Name"
  const [fullResults, setFullResults] = useState([]); // To store detailed results
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMoreResults, setHasMoreResults] = useState(true); // Track if more results are available
  const resultsRef = useRef(null); // Ref to the results container

  const fetchFullResults = async () => {
    let detailedResults = [];
    try {
        setLoading(true); // Start loading
        console.log('Fetching results');
        detailedResults = await ApiCaller('searchPeople_expanded', { name: searchTerm, numResults: resultsPerPage, startAt: (currentPage - 1) * resultsPerPage });

        if (detailedResults.length > 0) {
            setFullResults(prevResults => [...prevResults, ...detailedResults]); // Append new results
            console.log('Got results');
        } else {
            setHasMoreResults(false); // No more results available
        }
    } catch (error) {
        console.error("Error fetching full results:", error);
        setFullResults([]); // Set to empty array on error
    } finally {
        setLoading(false); // End loading
    }
  };

  useEffect(() => {
      fetchFullResults();
  }, [activeTab, currentPage]); // Depend on activeTab and currentPage


  return (
    <Box display="flex" flexDirection="column" height="100vh" padding={4}>
      <StubsHeader />

      {/* Left Column (20% White Space) */}
      <Box flex="0.1" bg="white">
        {/* Search Results Heading */}
        <Heading size="lg" width="100%" mb={4}>
          Search Results for: {searchTerm}
        </Heading>

      </Box>

      <StubsFooter />
    </Box>
  );
};

export default ShowResults;