import { useState, useEffect, useRef } from 'react';
import { ApiCaller } from './APIConnect'; // Adjust the path accordingly

export const fetchApi = async (apiFunction, apiQuery) => {
  try {
    const response = await ApiCaller(apiFunction, apiQuery);
    if (!response) {
      throw new Error('Error fetching data');
    }
    //console.log(response);
    return response; // Assuming the response is the data we want
  } catch (error) {
    // Log the error or handle it as needed
    console.error('fetchApi error:', error);
    throw error; // Re-throw the error so it can be caught by the caller
  }
};

const useApiCall = (apiFunction, apiQuery) => {
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isFirstRun = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const response = await ApiCaller(apiFunction, apiQuery);
      if (response) {
        setApiResponse(response);
        console.log(response);
      } else {
        setError('Error fetching data');
      }
      setLoading(false);
    };

    if (isFirstRun.current) {
        isFirstRun.current = false;
        fetchData();
    }
  }, [apiFunction, JSON.stringify(apiQuery)]);

  return { apiResponse, loading, error };
};

export default useApiCall;