import React, { useState, useRef, useEffect } from 'react';
import useApiCall from '../utils/APICall';
import { fetchApi } from '../utils/APICall';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Box, Heading, Text, VStack, HStack, UnorderedList, ListItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Tooltip, SimpleGrid, Badge, Card, Popover, Input, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, Button, ButtonGroup, PopoverHeader, PopoverBody, PopoverFooter, Icon, Link, Image, StatGroup, StatLabel, StatNumber, Stat, StatHelpText, StatArrow, Skeleton } from '@chakra-ui/react';
import { BsLinkedin } from "react-icons/bs";
import { BsAt, BsPersonCircle } from "react-icons/bs";
import { NumericFormat } from 'react-number-format';
import { convertToTitleCase } from '../utils/textFunctions';
import StubsHeader from './Header';
import StubsFooter from './Footer';

const ShowResults = () => {
  const { id } = useParams();

  let [personInfo, setPersonInfo] = useState(null);
  const [personHistory, setPersonHistory] = useState(null);
  const [organizationEmployees, setOrganizationEmployees] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call to get person and organization info
        const personData = await fetchApi('person', { id });
        setPersonInfo(personData); // This line is causing the error
    
        // Second API call to get salary history
        const salaryData = await fetchApi('personHistory', { id });
        setPersonHistory(salaryData);
    
        // Third API call to get organization employees, using org_id from personData
        if (personData && personData[0].ein) {
          const orgEmployeesData = await fetchApi('organizationEmployees', { ein: personData[0].ein });
          setOrganizationEmployees(orgEmployeesData);
        } else {
          console.log('No ein found in personData:', personData); // Log if org_id is missing
        }
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Handle loading and error states
  if (loading) {
    return (
      <Box display="flex" height="100vh" flexDirection="column" padding={4}>
        <StubsHeader />
        <Box display="flex" flex="1">
          <Box flex="0.1" bg="white" />
          <Box flex="0.9" display="flex" flexDirection="column" padding={4}>
            <Skeleton height="40px" mb={4} />
            <Box display="flex" flex="1">
              <Box flex="0.6" padding={4} bg="#F7FAFC">
                <VStack spacing={6} align="start">
                  <Skeleton height="200px" width="100%" mb={4} />
                  <Skeleton height="20px" width="80%" mb={2} />
                  <Skeleton height="20px" width="60%" mb={2} />
                  <Skeleton height="20px" width="100%" mb={2} />
                  <Skeleton height="20px" width="100%" mb={2} />
                  <Skeleton height="20px" width="100%" mb={2} />
                  <Skeleton height="20px" width="100%" mb={2} />
                </VStack>
              </Box>
              <Box flex="0.3" padding={4} bg="#F7FAFC">
                <VStack spacing={6} align="start">
                  <Skeleton height="20px" width="100%" mb={2} />
                  <Skeleton height="20px" width="100%" mb={2} />
                  <Skeleton height="20px" width="100%" mb={2} />
                  <Skeleton height="20px" width="100%" mb={2} />
                </VStack>
              </Box>
            </Box>
          </Box>
        </Box>
        <StubsFooter />
      </Box>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (Array.isArray(personInfo)) personInfo = personInfo[0];

  let recentSalary;
  let recentTitle;
  let calculatedSalary;
  if (Array.isArray(personHistory)) {
    personHistory.sort((a, b) => new Date(b.filingyear) - new Date(a.filingyear));
    recentSalary = personHistory[0].compensationamt + personHistory[0].reportablecompfromorgamt + personHistory[0].othercomp + personHistory[0].salary_relatedorgs;
    recentTitle = personHistory[0].jobtitle;
  } else {
    recentSalary = personHistory.compensationamt + personHistory.reportablecompfromorgamt + personHistory.othercomp + personHistory.salary_relatedorgs;
    recentTitle = personHistory.jobtitle;
  }

  return (
    <Box display="flex" height="100vh" flexDirection="column" padding={4}>
      <StubsHeader />

      {/* Main Content Area */}
      <Box display="flex" flex="1">
        {/* Left Column (20% White Space) */}
        <Box flex="0.1" bg="white" />

        {/* Right Column Container */}
        <Box flex="0.9" display="flex" flexDirection="column" padding={4}>
          {/* Search Results Heading */}
          <Heading size="lg" width="100%" mb={4}>
            {convertToTitleCase(personInfo.person_name)}
          </Heading>

          <Box display="flex" flex="1">
            {/* Person Information Column (50%) */}
            <Box flex="0.6" padding={4} bg="#F7FAFC">
              <VStack spacing={6} align="start">
                {/* Person Information Section */}
                <Box borderWidth="1px" borderRadius="md" padding={4} bg="white" width="100%">
                  <Heading size="md" mb={2} bg="green.500" color="white" padding={2}>Person or Director Information</Heading>
                  <Text fontWeight="bold" fontSize="2xl">{convertToTitleCase(personInfo.person_name)}</Text>
                  <Text fontWeight="normal">{convertToTitleCase(recentTitle)}</Text>
                  <Text fontWeight="normal">{convertToTitleCase(personInfo.name)}</Text>
                  <Text fontWeight="bold" mt={2} display="flex" alignItems="center">
                    Most Recent Reported Compensation: {recentSalary.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
                    <Tooltip label="The salary is displayed as the total amount of salary and direct compensation from the organization in the most recent filing year. This does not include non-salary benefits and compensation from related organizations">
                      <Badge colorScheme="teal" ml={2}>How do we calculate this?</Badge>
                    </Tooltip>
                  </Text>
                  {/* Accordion for Detailed Salary Information */}
                  <Accordion allowToggle border="none">
                    <AccordionItem border="none">
                      <AccordionButton paddingLeft={0} marginLeft={0} bg="gray.100">
                        <Box flex="1" textAlign="left" fontWeight="bold">
                          Detailed Salary Information
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <UnorderedList>
                          {personHistory[0].compensationamt !== null && (
                            <ListItem>Salary: {personHistory[0].compensationamt.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</ListItem>
                          )}
                          {personHistory[0].reportablecompfromorgamt !== null && (
                            <ListItem>Reportable Compensation from Organization: {personHistory[0].reportablecompfromorgamt.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</ListItem>
                          )}
                          {personHistory[0].othercomp !== null && (
                            <ListItem>Other Compensation: {personHistory[0].othercomp.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</ListItem>
                          )}
                          {personHistory[0].salary_relatedorgs !== null && (
                            <ListItem>Compensation from related organizations: {personHistory[0].salary_relatedorgs.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</ListItem>
                          )}
                        </UnorderedList>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  {/* Job description */}
                  <Box height={4} />
                  <Text fontWeight="bold">Job Summary:</Text>
                  <Text>Job description not available</Text>
                  {/* Socials */}
                  <Box display="flex" flexDirection="column" align="start" mt={4}>
                    <Heading size="md" mb={2}>Find {convertToTitleCase(personInfo.person_name)} Online</Heading>
                    <HStack spacing={4}>
                      <Link href={personInfo.linkedin} isExternal>
                        <Icon as={BsLinkedin} w={9} h={9} color='green.600' />
                      </Link>
                      <Link href={`mailto:${personInfo.email}`} isExternal>
                        <Icon as={BsAt} w={9} h={9} color='green.600' />
                      </Link>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Text color="gray.500" fontSize="sm">Wrong email or Linkedin? Please let us know</Text>
                        </PopoverTrigger>
                        <PopoverArrow />
                        <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
                          <PopoverHeader pt={4} fontWeight='bold' border='0'>
                            Update Contact Information
                          </PopoverHeader>
                          <PopoverArrow bg='blue.800' />
                          <PopoverCloseButton />
                          <PopoverBody>
                            <VStack spacing={4}>
                              <Input placeholder="LinkedIn URL" />
                              <Input placeholder="Email Address" />
                            </VStack>
                          </PopoverBody>
                          <PopoverFooter
                            border='0'
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            pb={4}
                          >
                            <Box fontSize='sm'></Box>
                            <ButtonGroup size='sm'>
                              <Button colorScheme='green' onClick={() => {
                              }}>Submit</Button>
                            </ButtonGroup>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                    </HStack>
                  </Box>

                  {/* Compensation History */}
                  <Heading size="md" mt={4} bg="green.500" color="white" padding={2}>Compensation History:</Heading>
                  <StatGroup>
                    {personHistory.map((entry, index) => {
                      let formattedComp = 0;
                      if (entry.reportablecompfromorgamt > 0) {
                        formattedComp = entry.reportablecompfromorgamt;
                      } else {
                        formattedComp = entry.compensationamt + entry.othercomp + entry.salary_relatedorgs;
                      }
                      formattedComp = formattedComp ? formattedComp.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0';
                      return (
                        <Stat key={index}>
                          <StatLabel fontSize="xl" fontWeight="bold" fontStyle="italic" mt={4}>{new Date(entry.filingyear).getFullYear()}</StatLabel>
                          <StatNumber>{formattedComp}</StatNumber>
                          <StatHelpText>
                            {index < personHistory.length - 1 && (
                              <>
                                {typeof entry.salary === 'number' && (
                                  <>
                                    <StatArrow type={entry.salary < personHistory.compensationHistory[index + 1].salary ? 'decrease' : 'increase'} />
                                    <Text color={entry.salary < personHistory.compensationHistory[index + 1].salary ? 'red' : 'green'}>
                                      {(((personHistory.compensationHistory[index + 1].salary - entry.salary) / entry.salary) * 100).toFixed(2)}%
                                    </Text>
                                  </>
                                )}
                              </>
                            )}
                          </StatHelpText>
                        </Stat>
                      );
                    })}
                  </StatGroup>
                  <Heading size="md" bg="green.500" p={2} mt={4} color="white">Other People in the Same Organization:</Heading>
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                    {organizationEmployees.map((member, index) => {
                      // Handle salary calculation
                      let formattedComp = 0;
                      if (member.reportablecompfromorgamt > 0) {
                        formattedComp = member.reportablecompfromorgamt;
                      } else {
                        formattedComp = member.compensationamt + member.othercomp + member.salary_relatedorgs;
                      }
                      formattedComp = formattedComp ? formattedComp.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0';

                      return (
                        member.id !== personInfo.id && (
                          <Card key={index} width="auto" height="auto" padding={4} bg="white" borderRadius="md">
                            <NavLink to={`/person/${member.id}`}>
                              <HStack>
                                <Icon as={BsPersonCircle} />
                                <Text fontWeight="bold">{convertToTitleCase(member.person_name)}</Text>
                              </HStack>
                            </NavLink>
                            <Text fontWeight="bold">{convertToTitleCase(member.title)}</Text>
                            <Text fontWeight="bold">Salary: <Badge>{formattedComp}</Badge></Text>
                          </Card>
                        )
                      );
                    })}
                  </SimpleGrid>
                </Box>
              </VStack>
            </Box>

            {/* Company Information Column (30%) */}
            <Box flex="0.3" padding={4} bg="#F7FAFC">
              <VStack spacing={6} align="start">
                {/* Company Information Section */}
                <Box borderWidth="1px" borderRadius="md" padding={4} bg="white" width="100%">
                  <Heading size="md" mb={2}>About the Organization</Heading>
                  <Text fontSize="sm" fontStyle="italic">Data from most recent public filing available</Text>
                  <Text fontWeight="bold" mt={2}>{personInfo.name}</Text>
                  <Text fontWeight="normal">{convertToTitleCase(personInfo.street)}<br />{convertToTitleCase(personInfo.city)}, {personInfo.zip} {personInfo.state}</Text>
                  <Text fontWeight="bold" mt={2}>EIN: {personInfo.ein.slice(0, 2) + '-' + personInfo.ein.slice(2)}</Text>
                  <Text fontWeight="bold">Revenue: <NumericFormat value={personInfo.revenue_amt} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Text>
                  <Text fontWeight="bold">Total Assets:  <NumericFormat value={personInfo.asset_amt} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Text>
                  <Text fontWeight="normal">
                    <Link href={`https://www.google.com/search?q=${encodeURIComponent(personInfo.name)}`} isExternal color="green.500" textDecoration="underline">
                      Search for this organization on Google
                    </Link>
                  </Text>
                </Box>
              </VStack>
            </Box>
          </Box>
        </Box>
      </Box>
      <StubsFooter />
    </Box>
  );
};

export default ShowResults;
