import React from 'react';
import { Box, Heading, Icon } from '@chakra-ui/react';
import { BsList } from "react-icons/bs"; // Importing BsList

const StubsHeader = () => {
  return ( 
    // Header
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" padding={4} bg="white" position="fixed" top={0} zIndex={1}>
      {/* Logo */}
      <Heading
        as="h1"
        size="lg"
        color="#00BD9D"
        fontFamily="sans-serif"
        fontSize="30px"
        cursor="pointer"
        onClick={() => window.location.href = '/'}
      >
        Stubs
      </Heading>

      {/* Menu Button */}
      <Icon as={BsList} w={10} h={10} color="teal.500" marginRight={4} />
    </Box>
  );
};

export default StubsHeader;