import React from 'react';
import { Box, Heading, Icon, Divider, SimpleGrid, VStack, Link } from '@chakra-ui/react'; // Ensure all necessary imports
import { BsList } from "react-icons/bs"; // Importing BsList

const StubsFooter = () => {
  return ( 
    <>
      <Divider borderColor="teal.500" width="100%" mt={8} />
      <Box as="footer" width="100%" padding={8}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box>
            <Heading size="md" mb={4}>About Stubs</Heading>
            <VStack align="start" spacing={2}>
              <Link href="/why-stubs" color="teal.500">Why Stubs?</Link>
              <Link href="/privacy-policy" color="teal.500">Privacy Policy</Link>
              <Link href="/faq" color="teal.500">FAQ</Link>
            </VStack>
          </Box>
          <Box>
            {/*}
            <Heading size="md" mb={4}>Industry Insights</Heading>
            <VStack align="start" spacing={2}>
              <Link href="/highest-paying-organizations" color="teal.500">Highest paying organizations</Link>
              <Link href="/salary-by-industry" color="teal.500">Salary by industry</Link>
              <Link href="/salary-by-job-title" color="teal.500">Salary by job title</Link>
            </VStack>
            */}
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default StubsFooter;