import React, { useState } from 'react';
import { Box, Center, Heading, Text, Input, Card, SimpleGrid, VStack, Link, Divider, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { BsClipboardData,BsCashStack,BsLightbulb } from "react-icons/bs";
import StubsFooter from './Footer';

const MainSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleSubmit = (e) => {
    e.preventDefault();
    // Redirect to the ShowResults component with the search term
    navigate('/results', { state: { searchTerm } }); // Use navigate to redirect
  };

  return (
    <Box
      bg="#FFFAF0"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={4}
    >
      <Heading
        as="h1"
        size="2xl"
        color="#00BD9D"
        fontFamily="sans-serif"
        fontSize="50px"
        mb={4}
      >
        Stubs
      </Heading>
      <Text
        fontFamily="'Source Serif Pro', serif"
        fontSize="32px"
        textAlign="center"
        mb={4}
        maxWidth="850px"
      >
        {/* All you need to know about compensation in the non-profit sector. Search compensation data from more than 10 million non-profit professionals and get the information you need to receive a fair and competitive salary. */}
        Stubs is your source of salary and compensation data for the non-profit sector. Find verified data from millions of people working in non-profits, private foundations and 501(c)(3) organizations. Search and find the information you need to get paid what you deserve
      </Text>
      <Center mb={4}>
        <InputGroup size="lg" as="form" onSubmit={handleSubmit}>
          <Input
            placeholder="Search for person, job title, organization name or EIN"
            height="48px"
            width="650px"
            borderRadius="md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
          />
          <InputRightElement width="auto">
            <Button type="submit" colorScheme="teal" height="48px" borderRadius="md" width="100px">
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
      </Center>
      <Box width="70%" display="flex" justifyContent="space-between" mt={8}>
        <Card width="30%" bg="white" borderRadius="md" boxShadow="md" padding={4} textAlign="center">
          <Box display="flex" justifyContent="center" mb={2}>
            <BsCashStack size="3em" color="teal" />
          </Box>
          <Heading size="md" mb={2}>Earn More</Heading>
          <Text>Showing up with the right information can get you a 30% pay hike in your next job. Learn about what your peers in the industry make, and find your number.</Text>
        </Card>
        <Card width="30%" bg="white" borderRadius="md" boxShadow="md" padding={4} textAlign="center">
        <Box display="flex" justifyContent="center" mb={2}>
            <BsLightbulb size="3em" color="teal" />
          </Box>
          <Heading size="md" mb={2}>Research</Heading>
          <Text>Are you on the board or compensation committee of a non-profit or a private foundation? Compile salary data from other organizations in your industry and geography to make sure you compensate correctly.</Text>
        </Card>
        <Card width="30%" bg="white" borderRadius="md" boxShadow="md" padding={4} textAlign="center">
          <Box display="flex" justifyContent="center" mb={2}>
            <BsClipboardData size="3em" color="teal" />
          </Box>
          <Heading size="md" mb={2}>Analyze</Heading>
          <Text>Are you in an HR department and it's time for your annual salary review? Make sure you show up well informed with current salary data from organizations of all sizes and all across the US.</Text>
        </Card>
      </Box>
      <StubsFooter />
    </Box>
  );
};

export default MainSearch;