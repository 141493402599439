import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import StubsHeader from './Header';
import StubsFooter from './Footer';

const PrivacyPolicy = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh" padding={4}>
      <StubsHeader />

      {/* Left Column (20% White Space) */}
      <Box flex="0.1" bg="white" />

      {/* Right Column Container */}
      <Box flex="0.9" display="flex" width="70%" flexDirection="column" padding="75px" bg="#F7FAFC">
        <Heading size="lg" mb={4}>Privacy Policy</Heading>
        <Text fontSize="lg" mb={4}>
        Your privacy is a top priority. We are committed to ensuring that our practices align with industry standards. This Privacy Policy outlines how we collect, use, and protect your information when you interact with our platform.
        </Text>
        <Text fontSize="lg" mb={4}>
        Information We Collect:<br/>
        We collect non-personally identifiable data such as your browsing behavior on our site, which helps us improve our platform and services. If you sign up for an account, we may collect basic contact information like your name and email address. Additionally, we may collect any search queries you submit as part of your use of the database.
        </Text>

        <Text fontSize="lg" mb={4}>
        How We Use Your Information:<br/>
We use the information collected to:<br/><br/>

	•	Improve user experience and functionality of the platform<br/>
	•	Communicate with you regarding updates, new features, or other relevant content<br/>
	•	Monitor and analyze usage patterns to better serve our users<br/>
        </Text>

        <Text fontSize="lg" mb={4}>
        Data Security:<br/>
        We employ robust security measures to protect your information. All data transmission is encrypted using standard SSL technology, and we regularly audit our systems to prevent unauthorized access.
        </Text>
        
        <Text fontSize="lg" mb={4}>
        Third-Party Sharing:<br/>
        We do not sell, trade, or otherwise transfer your personal information to outside parties, except as necessary to provide our services or comply with legal obligations.
        </Text>
      </Box>

      <StubsFooter />
    </Box>
  );
};

export default PrivacyPolicy;