import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import StubsHeader from './Header';
import StubsFooter from './Footer';

const WhyStubs = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh" padding={4}>
      <StubsHeader />

      {/* Left Column (20% White Space) */}
      <Box flex="0.1" bg="white" />

      {/* Right Column Container */}
      <Box flex="0.9" display="flex" width="70%" flexDirection="column" padding="75px" bg="#F7FAFC">
        <Heading size="lg" mb={4}>Why Stubs?</Heading>
        <Text fontSize="lg" mb={4}>
        At Stubs, we believe in the power of transparency to drive positive change. Our mission is to make salary information for nonprofit organizations easily accessible, helping to promote fairness and equity in compensation practices across the sector. By leveraging publicly available data, we aim to offer nonprofit professionals, boards, and stakeholders insights into how compensation compares across roles, organizations, and regions.
        </Text>
        <Text fontSize="lg" mb={4}>
          Our commitment to transparency is unwavering. We believe that by providing access to comprehensive salary data, we empower nonprofit organizations to make informed decisions that foster equity and trust within their teams.
        </Text>

        <Text fontSize="lg" mb={4}>
          Join us in our mission to create a more transparent and equitable nonprofit sector. Together, we can drive positive change and ensure that every professional is valued and compensated fairly.
        </Text>

        <Text fontSize="lg" mb={4}>
        Whether you're a nonprofit leader looking to ensure your organization is competitive, or a professional seeking transparency around your career path, Stubs provides the tools to explore compensation benchmarks in a meaningful way.
        </Text>
        
        <Text fontSize="lg" mb={4}>
        Our database includes compensation data sourced from IRS Form 990 filings, which we have meticulously parsed and organized to give users fast and reliable access to the information they need. We believe that shining a light on nonprofit salaries can help drive more informed decisions, ultimately leading to a more equitable sector.
        </Text>
      </Box>

      <StubsFooter />
    </Box>
  );
};

export default WhyStubs;